import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container, Image, Card } from 'react-bootstrap'
import './app.scss';
import rosie from './images/RosieBaptism.jpg';
import rose from './images/rose.svg';
import itMakesSense from './images/It Makes Sense.jpg';
import passingTheFaith from './images/Passing the Faith.jpg';
import youTube from './images/YouTube_400.jpg';
import Share from './Share';
import fromSomeone from './images/From Someone Who Loves You.jpg';

function Main() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-P248LQSJST');
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({ category, action })
    }
  }

  return (
    <>
      <Image src={rosie} className="img-fluid rounded" title="Rosie's Baptism" />
      <header>
        <h2 className="text-center book-even" >
          How God has lit a path...
        </h2>
        <h1 className="text-center book-title" >
          Intentionally Catholic
        </h1>
        <div className="text-center book-lit" >
          ~ Two Books, Videos &amp; Mission ~
        </div>
      </header>
      <main className="book-body" >

        <div className="book-section0">
          <Card bg="light">
            <Card.Body>
              <Card.Text>
                <span style={{ fontWeight: "bold" }}>Ralph Martin, S.T.D.</span>,
                a prominent Catholic teacher and author, endorsed
                my book, <span style={{ fontStyle: "italic" }}>Passing the Faith onto Loved Ones</span>,
                on which this mission is based. +Deacon John Beagan
              </Card.Text>
            </Card.Body>
          </Card>
        </div >

        <div className="book-section0">
          <div className="book-section1 book-the-video">Why This Mission?</div>
          <div className="book-section2">Disbelief</div>


          <div className="book-description">
            According to Pew Research, two-thirds of those who left Catholicism did so because
            they no longer believe. Moreover, a professional study in my parish revealed that half of the survey
            respondents (presumably our most motivated) do not strongly believe what the Church
            teaches on faith and morals — a significant factor causing lower enthusiasm.
          </div>
          <div className="book-description">
            However, in my experience and analysis, it is the Catholic faith, even with all its
            problems and scandals, that makes the greatest sense and is most likely true. In fact,
            I'm convinced.
          </div>

          <div className="book-description">
            Therefore, to address the disbelief negatively affecting people's faith,
            these books and videos present the same simple case based on evidence and
            common sense for believing in God, His beloved son Jesus, and the one Church he
            historically established. In particular, the aim is to help Catholics believe
            the obvious meaning of all God's words and invisible realities.
          </div>

          <div className="book-section2">Preparing to Stand Before God</div>
          <div className="book-description">
            What really compelled me to produce these works is the image of healthy middle-class
            people at the end of this busy life on earth standing before God, the Father
            of Jesus crucified, after neglecting and forgetting His only beloved son.
            How will that moment go?
          </div>

          <div className="book-description">
            Can we really expect to enter <span style={{ fontStyle: "italic" }}>intimate</span> communion
            with God &mdash; <span style={{ fontStyle: "italic" }}>God in us and us in God</span> &mdash; having never spent time
            getting to know him? Having ignored the crucifix, driven passed churches, and avoided
            faith conversations with loved ones? Having effectively decided on a daily basis to
            have no relationship with him? 
          </div>

          <div className="book-description">
            And after disregarding God's words, concluded what is good is bad, 
            and what is bad is good. Given Jesus' clear and dire warnings, this is an awful and unnecessary chance to take.
            Moreover, we have missed the opportunity and grace to love like God with 
            experiences of deep happiness and tastes of heaven.
          </div>

          <div className="book-description">
            Consequently, the goal of this mission is to encourage and help active Catholics
            have conversations with those they love and bring them back to church.
          </div>
        </div>

        <div className="book-section0 margin-top">
          <div className="book-section1 book-it-makes-sense" >
            The Mission
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For bishops and pastors...
            </div>
            <div id="" className="book-description" >
              who want to help their people believe and bring loved ones back to Church.
              This is a link to a 12-week parish mission with free resources.
            </div>
            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.intentionallycatholic.com/Believe"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'PageLinkBelieve')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#088c9b" }}
                  >
                    Goto Believe
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book-section0 margin-top2">
          <div className="book-section1 book-passing-the-faith" style={{ fontStyle: "italic" }}>
            Passing the Faith onto Loved Ones
          </div>

          <div className="book-description">
            <Card bg="light">
              <Card.Body>
                <Card.Text>
                  <p className="ralph-quote"><span style={{ fontWeight: "bold" }}>&ldquo;</span>
                    Deacon John is a clear thinker and clear writer and has written a book
                    that will be a real contribution to Passing the Faith onto Loved Ones.
                    <span style={{ fontWeight: "bold" }}>&rdquo;</span>
                  </p>
                  <p><span style={{ fontWeight: "bold" }}>~ Ralph Martin, S.T.D.</span></p>
                  <p>Director of Graduate Theology Programs in the New Evangelization, <br />
                    Sacred Heart Major Seminary, Archdiocese of Detroit</p>
                  <p>President,&nbsp;
                    <a href="https://www.renewalministries.net/"
                      target="_blank" rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      Renewal Ministries
                    </a>
                  </p>
                  <div>Author of many books including:
                    <span style={{ fontStyle: "italic" }}> A Church in Crisis</span> (new!),
                    <span style={{ fontStyle: "italic" }}> The Urgency of the New Evangelization</span>,
                    <span style={{ fontStyle: "italic" }}> The Final Confrontation</span>,
                    <span style={{ fontStyle: "italic" }}> Will Many Be Saved</span>,
                    <span style={{ fontStyle: "italic" }}> and The Fulfillment of All Desire (my favorite)</span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div >

          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who love the Church...
            </div>
            <div id="" className="book-description" >
              Best for teachers, ministers, staff, and other enthusiastic laypeople. Good
              for anyone who wants to pass the faith on to a loved one.
            </div>
            <div className="book-cover">
              <a href="https://www.amazon.com/dp/1659148014?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'CoverClickPassingTheFaith')}
                style={{ textDecoration: "none" }}
              >
                <Image src={passingTheFaith}
                  className="img-fluid rounded"
                  title="Passing the Faith onto Loved Ones"
                />
              </a>
            </div>

            <div className="book-button-outer">
              <div className="book-links">
                <a href="https://www.passingthefaithontolovedones.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'SiteLinkPassingTheFaith')}
                  style={{ textDecoration: "none" }}
                >
                  <span
                    className="book-button"
                    style={
                      { backgroundColor: "#ca8e4c" }
                    }>
                    Goto Book Website</span>
                </a>
              </div>
            </div>

          </div>
        </div>

        <div className="book-section0 margin-top2">
          <div className="book-section1 book-it-makes-sense" style={{ fontStyle: "italic" }}>
            It Makes Sense to Believe
          </div>
          <div id="" className="subsection">
            <div id="" className="book-section2" >
              For those who doubt or disbelieve...
            </div>
            <div id="" className="book-section2b" >
              or are lukewarm and disinterested...
            </div>
            <div id="" className="book-description" >
              Ideal for confirmation students &mdash; before they go to college! And it
              can be taught in one or two sessions. Likewise, for the parents of students
              in faith formation since they are the primary educators.
            </div>
            <div className="book-cover" >
              <a href="https://www.amazon.com/dp/B08BWFKBDK?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'CoverClickItMakesSense')}
                style={{ textDecoration: "none" }}>
                <Image src={itMakesSense}
                  className="img-fluid rounded"
                  title="It Makes Sense to Believe"
                />
              </a>
            </div>
            <div className="book-button-outer" >
              <div className="book-links">
                <a href="https://www.itmakessensetobelieve.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'SiteLinkItMakesSense')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#088c9b" }}
                  >
                    Goto Book Website
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="book-section0 margin-top2">
          <div className="book-section1 book-back-cover">The Video</div>

          <div className="book-section2">YouTube</div>

          <div className="book-description">
            This video is an invitation to send to fallen-away loved ones
            so they might consider the books' simple case for the faith
            and come to Mass.
          </div>

          <div className="book-links">
            <a href="https://youtu.be/GQb7dBQy6lM"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'FromSomeone-YouTube')}
            >
              <Image src={fromSomeone}
                className="img-fluid rounded"
                title="From Someone Who Loves You"
                style={{ border: "1px solid #DCDCDC" }}
              />
            </a>
            <div className="book-span">(11 minutes)</div>
          </div>

          <div className="book-description" style={{ marginTop: "2rem" }}>
            This is based on the book for those who prefer to watch a video and should strengthen
            Catholics' belief and zeal. It has also been segmented into 12
            short videos to provide more sharing options.
          </div>

          <div className="book-links">
            <a href="https://youtu.be/mV3tP0w3Edc"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'TheVideo-YouTube')}
            >
              <Image src={youTube}
                className="img-fluid rounded"
                title="It Makes Sense to Believe - The Video"
              />
            </a>
            <div className="book-span">(83 minutes)</div>
          </div>

          <div className="book-section2">12 Video Segments</div>
          <div className="book-links">

            {/* 1 */}
            <a href="https://youtu.be/f9Kn_ZVVARI"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '01-should-we')}
            >
              1-Should We Care?
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 2 */}
            <a href="https://youtu.be/qAPaOiQOKHE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '02-truth')}
            >
              2-Importance of Truth
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 3 */}
            <a href="https://youtu.be/egVKsIpmIqE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '03-is-god-dead')}
            >
              3-Is God Dead?
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 4 */}
            <a href="https://youtu.be/m1BMtAtO23o"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '04-reveal')}
            >
              4-Did God Reveal Himself?
            </a>
            <span className="book-span2"> (11&nbsp;min), </span>

            {/* 5 */}
            <a href="https://youtu.be/rrfp4G5s6mg"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '05-Love')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              5-Love
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 6 */}
            <a href="https://youtu.be/JHsuE3PYMq8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '06-evidence')}
            >
              6-Evidence
            </a>
            <span className="book-span2"> (4&nbsp;min), </span>

            {/* 7 */}
            <a href="https://youtu.be/JUr7l7o6vG8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '07-Evil')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              7-Evil
            </a>
            <span className="book-span2"> (20&nbsp;min), </span>

            {/* 8 */}
            <a href="https://youtu.be/0Fj5wAPt8UQ"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '08-suffering-death')}
            >
              8-Suffering &amp; Death
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 9 */}
            <a href="https://youtu.be/16lbIYsre3Y"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '09-little-faith')}
            >
              9-A Little Faith Is Needed
            </a>
            <span className="book-span2"> (8&nbsp;min), </span>

            {/* 10 */}
            <a href="https://youtu.be/8wDDdkTDI8M"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '10-sex')}
            >
              10-Sex, Sin &amp; Scandal
            </a>
            <span className="book-span2"> (7&nbsp;min), </span>

            {/* 11 */}
            <a href="https://youtu.be/BCjCYcULNMc"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '11-women')}
            >
              11-Women's Ordination
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 12 */}
            <a href="https://youtu.be/C84uxYDg9mU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '12-making-it')}
            >
              12-Making It into Heaven
            </a>
            <span className="book-span2"> (9&nbsp;min) </span>

          </div>
        </div>

        <div className="book-section2">Other Short Videos</div>
        <div className="book-links">

          {/* From Someone Who Loves You */}
          <a href="https://youtu.be/GQb7dBQy6lM"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'From-Someone')}
            style={{ fontWeight: "bold", fontStyle: "italic" }}
          >
            From Someone Who Loves You
          </a>
          <span className="book-span2"> (11&nbsp;min), </span>

          {/* Crosses - Rosie */}
          <a href="https://www.youtube.com/watch?v=hRQq8VR7Ne4"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'crosses')}
          >
            Crosses - Rosie
          </a>
          <span className="book-span2"> (4&nbsp;min), </span>

          {/* Salvation: Church in Crisis */}
          <a href="https://youtu.be/PHJmtRYd4XA"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Salvation')}
            style={{ fontWeight: "bold", fontStyle: "italic" }}
          >
            Salvation: Church in Crisis
          </a>
          <span className="book-span2"> (21&nbsp;min), </span>

          {/* Army in the Pews */}
          <a href="https://youtu.be/NyY_FUx4HoY"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Army')}
          >
            Army in the Pews
          </a>
          <span className="book-span2"> (12&nbsp;min), </span>

          {/* Intuitive Case */}
          <a href="https://youtu.be/2wrPySQKSlA"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Intuitive')}
          >
            An Intuitive Case for Jesus&apos; Dire Words
          </a>
          <span className="book-span2"> (14&nbsp;min), </span>

          {/* Sharing Our Reasons */}
          <a href="https://youtu.be/NqnOhfKP5-U"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Sharing')}
          >
            Sharing with Loved Ones the Reasons for Our Hope
          </a>
          <span className="book-span2"> (15&nbsp;min)</span>

        </div>


        <div className="book-section0 margin-top">
          <div className="book-section1 salvation-crisis" >
            Salvation: Church in Crisis
          </div>
          <div className="book-description">
            This article refers to a recent column (December 2020) by
            Cardinal George Pell and to an earlier one by Cardinal
            Avery Dulles. It explores what underlies the widespread
            presumption that almost everyone makes it to heaven
            despite the obvious meaning of Jesus' words. Then it reconsiders
            Jesus' difficult teaching in light of God who is love, the
            crucifixion of his only beloved son, and the intimate reality of heaven.
            The article can be read, downloaded or watched on
            YouTube.
          </div>
          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.IntentionallyCatholic.com/Salvation"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'GotoSalvation')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button salvation-crisis"
                  style={{ backgroundColor: "#9b0000" }}
                >
                  Goto Salvation
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="book-section0 margin-top2">

          <div className="book-section1" >
            Other Articles
          </div>
          <div className="book-description">
            (Read or Listen)
          </div>

          <div className="book-description" style={{ fontStyle: "italic", fontSize: "150%" }}>
            Army in the Pews
          </div>
          <div className="book-description">
            An important story about a passing opportunity to bring Catholics back to church.
            See <a
              href="https://www.IntentionallyCatholic.com/Army"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'MainArmy')}
            >
              IntentionallyCatholic.com/<b>Army</b>
            </a>.
          </div>

          <div className="book-description" style={{ fontStyle: "italic", fontSize: "150%" }}>
            An Intuitive Case for Jesus&apos; Dire Words
          </div>
          <div className="book-description">
            Contrary to popular &ldquo;feelings,&rdquo; this article considers
            the accuracy of Jesus&apos; words in light of how much God loves us,
            the crucifixion of his only beloved son, and the intimate
            reality of heaven. <a
              href="https://www.IntentionallyCatholic.com/Dire"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'MainDire')}
            >
              IntentionallyCatholic.com/<b>Dire</b>
            </a>.
          </div>

          <div className="book-description" style={{ fontStyle: "italic", fontSize: "150%" }}>
            Sharing with Loved Ones the Reasons for Our Hope
          </div>
          <div className="book-description">
            This brief article is all that’s needed to initiate conversations with loved ones and bring them back to church.
            See <a
              href="https://www.IntentionallyCatholic.com/Share"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'MainShare')}
            >
              IntentionallyCatholic.com/<b>Share</b>
            </a>.
          </div>

        </div>


        <div className="book-section0 margin-top2">
          <div className="book-section1 how-to-use-book" >
            How to Use These Books &amp; Videos?
          </div>

          <div className="book-description">
            The books, videos, and narrations on YouTube were created so people may
            read, watch or listen to this simple case for our faith and
            be prepared to stand before God our Father. If you agree with the content
            and approach, <span className="font-weight-bold">please promote it</span>.
            The manuscripts and YouTube presentations are free. And the Amazon
            versions are inexpensive.
          </div>

          <div className="book-description">
            Leaders of the Church at all levels
            must themselves fully believe to be most successful
            at bringing people back to Mass.
            A <span className="font-weight-bold">bishop</span> can
            use <a href="https://www.passingthefaithontolovedones.com"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'SiteLinkPassingTheFaith')}
              style={{ fontStyle: "italic" }} >
              Passing the Faith
              onto Loved Ones</a> to help his priests,
            deacons and teachers believe <span style={{ fontStyle: "italic" }}>all</span> God's words.
            Given the book’s simplicity, however,
            more intellectual sources may also be desired. That said, its ease of
            understanding for busy laypeople is an example worth
            considering.
          </div>

          <div className="book-description">
            In a similar way, <span className="font-weight-bold">pastors</span>,
            priests, deacons and teachers need to help their active members
            fully believe so they can in turn have conversations with loved ones and
            pass on the faith.&nbsp;
            <a href="https://www.ItMakesSenseToBelieve.com"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'SiteLinkItMakesSense')}
              style={{ fontStyle: "italic" }} >
              It Makes Sense to Believe</a>,
            the other book,
            is meant for those who lack interest, doubt, disbelieve, or otherwise feel
            practicing the faith is unnecessary. By default, I either hand out or refer
            people to this book.
          </div>

          <div className="book-description">
            Consider this link to a 12-week parish mission with free resources to help
            the flock believe and invite loved ones back to Mass:&nbsp;
            <a href="https://www.IntentionallyCatholic.com/Believe"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'PageLinkBelieve2')}
              style={{ fontStyle: "italic" }} >
              The <b>Mission</b></a>.
          </div>

          <div className="book-description">
            Whenever we’re preaching, teaching about the faith,
            or having conversations about it, we must always include
            some practical reasons for why we believe this makes sense.
            Over time, people will start to get it.
          </div>

          <div className="book-description">
            Please visit the book websites for links to YouTube narrations,
            manuscripts, Kindle version, and Amazon paperback.
            For bulk paperback orders, please contact me.
          </div>

          <div id="rosie" className="book-description">
            Lastly, band with kindred clergy
            who believe all God's words and
            want to share in Christ's saving mission: <a
              href="https://www.IntentionallyCatholic.com/Clergy"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'KindredClergy')}
              style={{ fontStyle: "italic" }} >
              IntentionallyCatholic.com/<b>Clergy</b>
            </a>.
          </div>

        </div>


        <div className="book-section0 margin-top" style={{ marginBottom: "5rem" }}>
          <div className="book-section1 book-rosie" >
            Rosie
          </div>
          <div className="book-description">
            Five days before Christmas 2019, at 5:00am, Charlie discovered his daughter's lifeless body. Rosie was a
            perfectly healthy 2-year-old. She was Laura and Charlie's only child, and our only grandchild. In the following
            links, they discussed what happened, and how their Catholic faith pulled them through.
          </div>

          <div className="book-description">
            In this secular podcast, <b>Laura and Charlie</b> told their story and provided some raw details. The
            interviewer was excellent. They also wove in how their faith helped. <a
              href="https://open.spotify.com/episode/1sP0f7b2Hky0Vqa4aKcyE8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie1')}
              style={{ fontStyle: "italic" }} >
              Tough Mudder Podcast
            </a>.
          </div>

          <div className="book-description">
            In this video, <b>Laura</b> spoke to 300 women at Long Beach Catholic Community
            during an evening of recollection. Her presentation begins after the 22 minute mark
            and they watch a 2 minute video which is not visible. <a
              href="https://www.youtube.com/watch?v=zAQtXncFZmU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie2')}
              style={{ fontStyle: "italic" }} >
              A Rose in Heaven | BC Ladies Event
            </a>.
          </div>

          <div className="book-description">
            In this video, <b>Charlie</b> spoke to 50 Beach Catholic men during a day of retreat. <a
              href="https://www.youtube.com/watch?v=SGwfpKVPto8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie3')}
              style={{ fontStyle: "italic" }} >
              Charlie's Presentation
            </a>.
          </div>
        </div>

        <Share />

        <div className="book-section0">
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback.
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </main>
    </>
  );
}

export default Main;
